<template>
	<div>
		<skeleton-loading v-if="requestCalls > 0"></skeleton-loading>

		<div v-else>
			<div class="row">
	            <div class="col-6">
	              <h3>{{ tt("mcr_information") }}</h3>
	            </div>
	            <div v-if="formType == 'edit'" class="col-6 text-right">
	              <base-button
	                size="sm"
	                type="default"
	                @click="editMcrInformation"
	                >{{ tt("edit") }}</base-button
	              >
	            </div>
	        </div>
	        <div class="row">
	            <div class="col-12">
	              <table class="table table-responsive-md table-bordered">
	                <tbody>
	                  <tr>
	                    <th class="bg-secondary">{{ tt("subject") }}</th>
	                    <td colspan="3">{{ mcrInformation.subject }}</td>
	                  </tr>
	                  <tr>
	                    <th class="bg-secondary">{{ tt("type") }}</th>
	                    <td>{{ mcrInformation.form_description }}</td>
	                    <th class="bg-secondary">{{ tt("created_by") }}</th>
	                    <td>{{ mcrInformation.created_by }}</td>
	                  </tr>
	                  <tr>
	                    <th class="bg-secondary">{{ tt("status") }}</th>
	                    <td>{{ mcrInformation.status }}</td>
	                    <th class="bg-secondary">{{ tt("created_at") }}</th>
	                    <td>{{ format_date(mcrInformation.created_at) }}</td>
	                  </tr>
	                </tbody>
	              </table>
	            </div>
	        </div>
		</div>

        <!-- MODAL CHANGE SUBJECT -->
        <validation-observer v-slot="{ invalid }">
	      <modal :show.sync="formMcrInformation.show">
	        <template slot="header">
	          <h5 class="modal-title">{{ tt("edit_subject") }}</h5>
	        </template>
	        <div>
	          <label class="form-control-label">{{ tt("subject") }} <span class="text-danger">*</span></label>
	          <base-input
	            :name="tt('subject')"
	            :placeholder="tt('subject')"
	            v-model="mcrInformationEdit.new_subject"
	            rules="required"> 	
	           </base-input>
	        </div>
	        <template slot="footer">
	          <base-button
	            type="secondary"
	            @click="formMcrInformation.show = false"
	            >{{ tt("close") }}</base-button>
	          <base-button
	            type="primary"
	            v-on:click="updateMcrInformation"
	            :disabled="btnUpdate.onLoading || invalid">
	            <span v-if="btnUpdate.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span>
	            <span v-else>{{ tt("update") }}</span>
	          </base-button>
	        </template>
	      </modal>
	    </validation-observer>
	</div>
</template>

<script>
	import Api from "@/helpers/api";
	import draftList from "@/services/material/draftList.service";
	var moment = require('moment');

	export default {
		name: 'McrHeader',
		data() {
			return {
				moment:moment,
				requestCalls: 0,
				mcrInformation: {},
				formMcrInformation: {
			        show: false
			    },
			    mcrInformationEdit: {
			        new_subject: ""
			    },
			    btnUpdate: {
			        onLoading: false
			    },

				mcr_code: this.$route.params.mcr_code,
				token: this.$route.params.token,
				formType: this.$route.params.type
			}
		},
		mounted() {
			this.get();
		},
		methods: {
			get() {
				this.requestCalls++;

				let context = this;
				Api(context, draftList.getMcrHeader(context.mcr_code, context.token))
		        .onSuccess(function(response) {
					context.mcrInformation = response.data.data[0];
					context.mcrInformationEdit.new_subject = response.data.data[0].subject;
		        })
		        .onFinish(function() {
		        	context.requestCalls--;
		        })
		        .call();
		    },
		    editMcrInformation() {
			  this.formMcrInformation.add = false;
			  this.formMcrInformation.show = true;
			},
			updateMcrInformation() {
			  let context = this;
			  // context.errorMessage = null;
			  context.btnUpdate.onLoading = true;
			  Api(
			    context,
			    draftList.updateMcrSubject(
			      context.mcrInformation.mcr_code,
			      context.mcrInformation.token,
			      context.mcrInformationEdit
			    )
			  )
			    .onSuccess(function(response) {
			      context.mcrInformation.subject =
			        context.mcrInformationEdit.new_subject;
			      context.$notify({
			        message: response.data.message,
			        type: "success",
			        verticalAlign: "bottom",
			        horizontalAlign: "left"
			      });
			    })
			    .onFinish(function() {
			      context.btnUpdate.onLoading = false;
			      context.formMcrInformation.show = false;
			    })
			    .call();
			},
			format_date(value){
                if (value) {
                   return moment(String(value)).locale('id').format('LLL')
                }
            },
		}
	};
</script>