<template>
	<div>
		<skeleton-loading v-if="requestCalls > 0"></skeleton-loading>

		<div v-else>
			<div class="row">
				<div class="col">
					<h3>{{ tt("mcr_item") }}</h3>
				</div>
				<div class="col mb-2">
                    <input type="text" class="form-control form-control-sm text-center border-radius-20" :placeholder="tt('search_mcr_item')" v-model="search" v-on:keyup="filter" />                
                </div>
				<div v-if="formType == 'detail' || formType == 'approval'" class="col text-right">
					<base-button size="sm" type="default" @click="tracking()">{{ tt("tracking") }}</base-button>
				</div>
				<div v-if="formType == 'edit'" class="col text-right">
					<base-button v-if="mcrType == 'A1' || mcrType == 'A2' || mcrType == 'A2a' || mcrType == 'A2b' || mcrType == 'A3' || mcrType == 'A4' || mcrType == 'B5' " size="sm" type="default" @click="ShowImportMcrItem()">{{ tt("import_mcr_item") }}</base-button>
					<router-link :to="'/material/mcr-form-add/'+mcrType+'/'+mcr_code+'/'+token+'/'+'add'" v-if="formType == 'edit'">
						<base-button size="sm" type="default">{{ tt("add_new") }}</base-button>
					</router-link>
				</div>
			</div>
			<el-table class="table-responsive table-flush" header-row-class-name="thead-light" :data="mcrItem">
				<el-table-column :label="tt('material_number')" :prop="tt('material_number')" min-width="200px" sortable v-if="mcrType == 'A2' || mcrType == 'A2a' || mcrType == 'A2b' || mcrType == 'A3' || mcrType == 'B3a' || mcrType == 'B3b' || mcrType == 'B4'">
					<template v-slot="{ row }">{{ row.material_number }}</template>
				</el-table-column>

				<el-table-column :label="tt('specification')" :prop="tt('specification')" min-width="200px" sortable v-if="mcrType == 'A1'">
					<template v-slot="{ row }">{{ row.specification }}</template>
				</el-table-column>

				<el-table-column :label="tt('item_name')" :prop="tt('item_name')" min-width="200px" sortable v-if="mcrType == 'A1' || mcrType == 'A2' || mcrType == 'A2a'">
				  	<template v-slot="{ row }">{{ row.item_name }}</template>
				</el-table-column>

				<el-table-column :label="tt('item_name')" :prop="tt('item_name')" min-width="200px" sortable v-if="mcrType == 'B2' || mcrType == 'B3a' || mcrType == 'B3b' || mcrType == 'B4'">
				  	<template v-slot="{ row }">{{ row.prc_inc +' - '+ row.prc_item_name }}</template>
				</el-table-column>

				<el-table-column :label="tt('description')" :prop="tt('description')" min-width="200px" sortable v-if="mcrType == 'B1'">
					<template v-slot="{ row }">{{ row.specification }}</template>
				</el-table-column>

				<el-table-column :label="tt('deletion_level')" :prop="tt('deletion_level')" min-width="200px" sortable v-if="mcrType == 'A4'">
					<template v-slot="{ row }">{{ row.deletion_level }}</template>
				</el-table-column>

				<el-table-column :label="tt('reason')" :prop="tt('reason')" min-width="200px" sortable v-if="mcrType == 'B1' || mcrType == 'A4' || mcrType == 'A2b'">
					<template v-slot="{ row }">{{ row.reason }}</template>
				</el-table-column>

				<el-table-column :label="tt('del_reason')" :prop="tt('del_reason')" min-width="200px" sortable v-if="mcrType == 'B5'">
					<template v-slot="{ row }">{{ row.del_reason }}</template>
				</el-table-column>
				
				<el-table-column :label="tt('material_number_from')" :prop="tt('material_number_from')" min-width="200px" sortable v-if="mcrType == 'B5'">
					<template v-slot="{ row }">{{ row.material_number_from }}</template>
				</el-table-column>

				<el-table-column :label="tt('plant_code_from')" :prop="tt('plant_code_from')" min-width="200px" sortable v-if="mcrType == 'B5'">
					<template v-slot="{ row }">{{ row.plant_code_from }}</template>
				</el-table-column>

				<el-table-column :label="tt('material_number_to')" :prop="tt('material_number_to')" min-width="200px" sortable v-if="mcrType == 'B5'">
					<template v-slot="{ row }">{{ row.material_number_to }}</template>
				</el-table-column>

				<el-table-column :label="tt('plant_code_to')" :prop="tt('plant_code_to')" min-width="200px" sortable v-if="mcrType == 'B5'">
					<template v-slot="{ row }">{{ row.plant_code_to }}</template>
				</el-table-column>

				<el-table-column :label="tt('status')" :prop="tt('status')" min-width="200px" sortable v-if="formType == 'approval'">
					<template v-slot="{ row }">
						<label class="badge badge-danger" v-if="row.status == 'Rejected'">{{ row.status }}</label>
						<label class="badge badge-warning" v-else-if="row.status == 'Revised'">{{ row.status }}</label>
						<label class="badge badge-success" v-else-if="row.status == 'Approved'">{{ row.status }}</label>
					</template>
				</el-table-column>

				<el-table-column :label="tt('status_process')" :prop="tt('status_process')" min-width="200px" sortable v-if="formType == 'approval'">
					<template v-slot="{ row }">{{ row.status_process }}</template>
				</el-table-column>

				<el-table-column  prop="action" width="100">
				  <template  v-slot="{ row }">
				    <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true">
				      <span class="btn btn-sm btn-icon-only text-light">
				        <i class="fas fa-ellipsis-v mt-2"></i>
				      </span>

				      <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
				      	<el-dropdown-item :command="{action: 'edit',mcrItemCode: row.mcr_item_code}" v-if="authUserPermission['mcr-approval-edit'] && (formType == 'edit' && row.status != 'Rejected') || (formType == 'approval' && row.status != 'Rejected')">{{ tt("edit") }}</el-dropdown-item>
				        <el-dropdown-item :command="{action: 'detail',mcrItemCode: row.mcr_item_code}">{{ tt("detail_or_preview") }}</el-dropdown-item>
				        <el-dropdown-item :command="{action: 'revise',mcrItemCode: row.mcr_item_code}" v-if="formType == 'approval' && authUserPermission['mcr-approval-revise'] && row.status != 'Rejected'">Revise</el-dropdown-item>
	                    <el-dropdown-item :command="{action: 'reject',mcrItemCode: row.mcr_item_code}" v-if="formType == 'approval' && authUserPermission['mcr-approval-reject'] && row.status != 'Rejected'">Reject</el-dropdown-item>
	                    <el-dropdown-item :command="{action: 'process',mcrItemCode: row.mcr_item_code}" v-if="formType == 'approval' && authUserPermission['mcr-approval-process'] && row.status != 'Rejected'">Process</el-dropdown-item>
	                    <el-dropdown-item :command="{action: 'process-detail',mcrItemCode: row.mcr_item_code}" v-if="formType == 'approval' && (mcrType == 'A1' || mcrType == 'A2' || mcrType == 'A2a' || mcrType == 'A2b' || mcrType == 'A3') && authUserPermission['mcr-approval-detail-process']">Process Detail</el-dropdown-item>
	                    <el-dropdown-item :command="{action: 'note',mcrItemCode: row.mcr_item_code}" v-if="formType == 'detail' || formType == 'approval'">Note</el-dropdown-item>
				        <el-dropdown-item :command="{action: 'delete',id: row.id}" v-if="formType == 'edit'">{{ tt("delete") }}</el-dropdown-item>
				      </el-dropdown-menu>
				    </el-dropdown>
				  </template>
				</el-table-column>
			</el-table>
		</div>

		<!-- NOTE MODAL -->
	    <validation-observer v-slot="{invalid}">
		    <modal :show.sync="noteModal.show">
	            <template slot="header">
	                <h5 class="modal-title">{{ noteModal.title }}</h5>
	            </template>
	            <label class="form-control-label">Note <span class="text-danger">*</span></label>
	            
	            <base-input :name="tt('note')" rules="required">
	            	<el-input
		              type="textarea"
		              :rows="4"
		              v-model="noteModal.text">
		            </el-input>
	            </base-input>

	            <template slot="footer">
	                <base-button type="secondary" @click="noteModal.show = false">{{ tt('close') }}</base-button>
	                <base-button type="primary" v-on:click="approval()" :disabled="btnApproval.onLoading || invalid">
	                	<span v-if="btnApproval.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt('please_wait') }}</span>
	                    <span v-else>{{ noteModal.button }}</span>
	                </base-button>
	            </template>
	        </modal>
	    </validation-observer>

	    <!-- TRACKING MODAL -->
	    <modal :show.sync="trackingModal.show" size="lg">
	          <template slot="header">
	              <h5 class="modal-title">{{ tt('approval_tracking') }}</h5>
	          </template>
	          <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="trackingModal.data" v-if="!onLoadTracking">
	              <el-table-column :label="tt('date')" :prop="tt('date')" min-width="100px" sortable>
	                  <template v-slot="{row}">
	                      {{ row.created_at }}
	                  </template>
	              </el-table-column>
	              <el-table-column :label="tt('status')" :prop="tt('status')" min-width="100px" sortable>
	                  <template v-slot="{row}">
	                      {{ row.message }}
	                  </template>
	              </el-table-column>
	              <el-table-column :label="tt('approver')" :prop="tt('approver')" min-width="100px" sortable>
	                  <template v-slot="{row}">
	                      {{ row.name }}
	                  </template>
	              </el-table-column>
	              <el-table-column :label="tt('message')" :prop="tt('message')" min-width="100px" sortable>
	                  <template v-slot="{row}">
	                      {{ row.note }}
	                  </template>
	              </el-table-column>
	          </el-table>
	          <page-loading v-else/>
	    </modal>

	    <!-- NOTE ITEM MODAL -->
	    <modal :show.sync="detailNoteModal.show" size="lg">
	          <template slot="header">
	              <h5 class="modal-title">{{ tt('mcr_item_note') }}</h5>
	          </template>
	          <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="detailNoteModal.data" v-if="!onLoadItemNote">
	              <el-table-column :label="tt('type')" :prop="tt('type')" min-width="100px" sortable>
	                  <template v-slot="{row}">
	                      {{ row.type }}
	                  </template>
	              </el-table-column>
	              <el-table-column :label="tt('note')" :prop="tt('note')" min-width="150px" sortable>
	                  <template v-slot="{row}">
	                      {{ row.note }}
	                  </template>
	              </el-table-column>
	              <el-table-column :label="tt('sender')" :prop="tt('sender')" min-width="80px" sortable>
	                  <template v-slot="{row}">
	                      {{ row.sender }}
	                  </template>
	              </el-table-column>
	              <el-table-column :label="tt('created_at')" :prop="tt('created_at')" min-width="100px" sortable>
	                  <template v-slot="{row}">
	                      {{ row.created_at }}
	                  </template>
	              </el-table-column>
	          </el-table>
	          <page-loading v-else/>
	    </modal>

	    <!-- IMPORT MCR ITEM MODAL -->
	    <modal :show.sync="importMcrItemModal.show">
	        <template slot="header">
		        <h5 class="modal-title">{{ tt('import_mcr_item') }}</h5>
	        </template>
		        <label class="form-control-label">Import File</label>
		        <file-input @change="filesChange"  :ref="'attachment'" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"></file-input>
		        <hr>
		        <div class="col-md-6 mt-4 ml--3">
		        	<label class="form-control-label mb-1"><span style="font-size: 12px;">Download Template</span></label>
			        <select class="form-control form-control-sm" v-model="downloadTemplateMcrItem" @change="downloadTemplate">
		                <option value="">Choose Template</option>
		                <option v-for="tmi in templateMcrItem" :value="tmi">{{ tmi }}</option>
		            </select>
	            </div>
	        <template slot="footer">
                <base-button type="secondary" @click="importMcrItemModal.show = false">{{ tt('close') }}</base-button>
                <base-button type="primary" v-on:click="ImportMcrItem" :disabled="btnSave.onLoading">
                    <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                    <span v-else>
                        <span>{{ tt('add') }}</span>
                    </span>
                </base-button>
            </template>
	    </modal>

	</div>
</template>

<script>
	import {mapState} from 'vuex'
	import Api from "@/helpers/api";
	import draftList from "@/services/material/draftList.service";
	import mcrApproval from '@/services/dashboard/mcrApproval.service';
	import baseApiUrl from '@/configs/config';

	export default {
	    data() {
	    	return {
	    		requestCalls: 0,
	    		onLoadTracking: true, 
	    		onLoadItemNote: true, 
	    		onloadSkeleteon: true,
	    		btnApproval: {
	    			onLoading: false
	    		},
	    		btnSave: {
                    onLoading: false
                },
	    		noteModal: {
                	show: false,
                	title: '',
                	text: '',
                	mandatory: '',
                	button: ''
                },
                trackingModal: {
                	show: false,
                	data: []
                },
                importMcrItemModal: {
                	show: false,
                	data: []
                },
                detailNoteModal: {
                	show: false,
                	data: []
                },
                attachmentDocument: {
			        attachment: [],
			    },
			    downloadTemplateMcrItem: '',
			    templateMcrItem: [
			    	'A1_MCR_Form_Create_New_User.xlsx',
			    	'A2_MCR_Form_Change_Client_User.xlsx',
			    	'A2_MCR_Form_Change_Plant_User.xlsx',
			    	'A3_MCR_Form_Extend_User.xlsx',
			    	'A4_MCR_Form_Deletion_User.xlsx',
			    	'B5_MCR_Form_Konsolidasi.xlsx',
			    ],
			    tokenUrl: localStorage.token,
                apiUrl: baseApiUrl.apiUrl,
	    		mcrItem: [],
	    		search: '',
	    		mcrType: '',
	    		mcrItemCode: '',
	    		mcr_code: this.$route.params.mcr_code,
				token: this.$route.params.token,
	    		formType: this.$route.params.type
	    	}
	    },
	    computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
	    mounted() {
	    	this.getMcrType();
	    	this.getMcrItem();
	    },
	    methods: {
	    	getMcrType() {
	    		this.requestCalls++;

	    		let context = this;
				Api(context, draftList.getMcrHeader(context.mcr_code, context.token))
		        .onSuccess(function(response) {
		          context.mcrType = response.data.data[0].form_type;
		        })
		        .onError(function(error) {
		          if (error.response.status == 404) {
		            context.mcrType = '';
		          }
		        })
		        .onFinish(function() {
		        	context.requestCalls--;
		        })
		        .call();
	    	},
	    	getMcrItem() {
	    		if (this.onloadSkeleteon == true) {
	    			this.requestCalls++;
	    		}

				let context = this;
				Api(context, draftList.getMcrItem(context.mcr_code, context.token, {search: this.search}))
		        .onSuccess(function(response) {
					context.mcrItem = response.data.data;
		        })
		        .onError(function(error) {
		          if (error.response.status == 404) {
		            context.mcrItem = [];
		          }
		        })
		        .onFinish(function() {
		        	context.requestCalls--;
		        })
		        .call();
		    },
		    handleTableAction(command) {
		    	if (command.action == 'detail') {
		    		this.$router.push('/material/mcr-form-detail/'+this.mcrType+'/'+this.mcr_code+'/'+command.mcrItemCode+'/'+this.token+'/'+'detail')
		    	} else if (command.action == 'edit') {
		    		this.$router.push('/material/mcr-form-detail/'+this.mcrType+'/'+this.mcr_code+'/'+command.mcrItemCode+'/'+this.token+'/'+'edit')
		    	} else if (command.action == 'delete') {
                	this.remove(command.id);
                } else if (command.action == 'revise') {
                	this.insertNote('Revise');
                	this.mcrItemCode = command.mcrItemCode;
                } else if (command.action == 'reject') {
                	this.insertNote('Reject');
                	this.mcrItemCode = command.mcrItemCode;
                } else if (command.action == 'process') {
                	// Untuk form dari user (A1,A2,A3) tombol process diarahkan ke form Approval Process
                	// Untuk form dari cataloguer dan form deletion tombol process diarahkan ke form edit

                	if (this.mcrType == 'A1' || this.mcrType == 'A2a' || this.mcrType == 'A2b' || this.mcrType == 'A3') {
                		this.$router.push('/material/mcr-form-approval-process/'+this.mcr_code+'/'+command.mcrItemCode+'/'+this.token+'/approval/'+this.mcrType);
                	} else if (this.mcrType == 'A4' || this.mcrType == 'B1' || this.mcrType == 'B2' || this.mcrType == 'B3a' || this.mcrType == 'B3b' || this.mcrType == 'B4' || this.mcrType == 'B5') {
                		this.$router.push('/material/mcr-form-detail/'+this.mcrType+'/'+this.mcr_code+'/'+command.mcrItemCode+'/'+this.token+'/'+'edit')
                	}
                } else if (command.action == 'process-detail') {
                	this.$router.push('/material/mcr-form-approval-process/'+this.mcr_code+'/'+command.mcrItemCode+'/'+this.token+'/approval-detail/'+this.mcrType);
                } else if (command.action == 'note') {
                	this.detailNote(command.mcrItemCode);
                }
            },
		    remove(id) {
		      let context = this;
		      context.confirmDialog(context.tt("confirm_delete")).then(result => {
		        if (result.value) {
		          Api(context, draftList.deleteMcrItem(id))
		            .onSuccess(function(response) {
		              context.$notify({
		                message: response.data.message,
		                type: "success",
		                verticalAlign: 'bottom', 
						horizontalAlign: 'left'
		              });
		              context.getMcrItem();
		              context.$parent.get();
		            })
		            .call();
		        }
		      });
		    },
		    insertNote(type) {
		    	let context = this;
		    	context.noteModal.button = type;
		    	context.noteModal.show = true;
		    	context.noteModal.text = '';

		    	switch(type) {
		    		case 'Revise':
		    			context.noteModal.title = 'Revise Item';
		    			context.urlApproval = 'revise-item'
		    			break;
		    		case 'Reject':
		    			context.noteModal.title = 'Reject Item';
		    			context.urlApproval = 'reject-item'
		    			break;
		    	}
		    },
		    approval() {
		    	let context = this;
		    	context.btnApproval.onLoading = true;

				Api(context, mcrApproval.approval(context.urlApproval, context.mcrItemCode, {note : context.noteModal.text})).onSuccess(function(response) {
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.noteModal.show = false;
                    context.noteModal.text = '';
                }).onFinish(function() {
                    context.btnApproval.onLoading = false;
                    context.getMcrItem();
                    context.$parent.get();
                })
                .call();
		    },
		    tracking() {
				let context = this;
				context.trackingModal.show = true;

				Api(context, draftList.log_approval(context.mcr_code))
				.onSuccess(function(response) {
					context.trackingModal.data = response.data.data;
					context.onLoadTracking = false; 
				})
				.onError(function(error) {
					if (error.response.status == 404) {
					  context.trackingModal.data = [];
					  context.onLoadTracking = false;
					}
				})
				.call();
			},
			detailNote(mcrItemCode) {
				let context = this;
				context.detailNoteModal.show = true;

				Api(context, draftList.getMcrItemNote(mcrItemCode))
				.onSuccess(function(response) { 
					context.detailNoteModal.data = response.data.data;
					context.onLoadItemNote = false; 
				})
				.onError(function(error) {
					if (error.response.status == 404) {
					  context.detailNoteModal.data = [];
					  context.onLoadItemNote = false;
					}
				})
				.call();
			},
			ShowImportMcrItem() {
				this.importMcrItemModal.show = true;
			},
			filesChange(files) {
                this.attachmentDocument.attachment = files[0];
            },
			ImportMcrItem() {                 
                this.btnSave.onLoading = true;
                let api = null;
                let context = this;
                let formData = new FormData(); 

                formData.append('select_file', this.attachmentDocument.attachment);
                
                api = Api(context, draftList.import_mcr_item(context.mcr_code, formData));
                api.onSuccess(function(response) {
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success'
                    });
                    context.getMcrItem();
                    context.$parent.get();
                    context.importMcrItemModal.show = false;
                }).onError(function(error) { 
                	context.$notify({
                        message: error.response.data.message,                  
                        type: 'danger',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });                   
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnSave.onLoading = false;   
                })
                .call();
            },
            downloadTemplate(){
            	if (this.downloadTemplateMcrItem != '') {
            		location.href = this.apiUrl+'emcr/download-template-mcr-item/'+this.downloadTemplateMcrItem+'?token='+this.tokenUrl;
            	}
            },
            filter() {
                let context = this;
                context.onloadSkeleteon = false;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    context.getMcrItem()
                }, 100);
            },
	    }
	};	
</script>